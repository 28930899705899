import React from "react"
import { SentryLoggerDetailed } from "@heyhabito/sentry"
import { Body } from "design-kit"
import { useSentry } from "@heyhabito/sentry"

interface ErrorBoundaryProps {
  sentryLogger: SentryLoggerDetailed
  identifier?: string
  children: React.ReactNode
}

interface ErrorBoundaryState {
  error: Error | null
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: Error): void {
    let location = "unknown"
    if (window) {
      location = window.location.href
    }
    this.setState({ error })
    this.props.sentryLogger({
      description: `ErrorBoundary: ${error.message}`,
      info: { cause: error, location, identifier: this.props.identifier },
    })
  }

  render(): React.ReactNode {
    return this.state.error ? (
      <Body>Sorry, something went wrong. Please try reloading the page.</Body>
    ) : (
      this.props.children
    )
  }
}

export const SentryErrorBoundary: React.FunctionComponent<{
  identifier?: string
  children?: React.ReactNode
}> = props => {
  const { logToSentryDetailed } = useSentry()

  return (
    <ErrorBoundary
      sentryLogger={logToSentryDetailed}
      identifier={props.identifier}
    >
      {props.children}
    </ErrorBoundary>
  )
}
