import React, { createContext, useState, useEffect, ReactNode } from "react"

export const utmKeys = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "gclid",
  "adgroup",
] as const

export type UtmKey = (typeof utmKeys)[number]

export type UtmMap = Record<UtmKey, string | null>

export const buildUtmMap = <T,>(f: (_: UtmKey) => T): Record<UtmKey, T> =>
  Object.fromEntries(utmKeys.map(key => [key, f(key)])) as Record<UtmKey, T>

export const emptyMap: UtmMap = buildUtmMap(() => null)

export const UtmContext = createContext<UtmMap>(emptyMap)

interface UtmProviderProps {
  children: ReactNode
}

export const UtmProvider: React.FC<UtmProviderProps> = ({ children }) => {
  const [utmMap, setUtmMap] = useState<UtmMap>(emptyMap)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      const newMap = buildUtmMap(key => params.get(key))

      setUtmMap(newMap)
    }
  }, [])

  return <UtmContext.Provider value={utmMap}>{children}</UtmContext.Provider>
}
