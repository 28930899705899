/* Google Tag Manager is terrible for page performance, (ask Google's own
 * performance monitoring tools)
 *
 * Given that we have to suffer it for now, we can slightly improve render speed
 * by 'defer'ing the script rather than loading it 'async'.
 *
 * This means the script will only run once the rest of the DOM has loaded.
 */

const loadGoogleTagManager = gtmAppId => `;(function(w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : ""
  j.defer = true
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  f.parentNode.insertBefore(j, f)
})(window, document, "script", "dataLayer", "${gtmAppId}")
`

module.exports = { loadGoogleTagManager }
